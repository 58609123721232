$(document).ready(function(){
	$(window).load(function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder'),
		$ajaxUrl = $('.ajaxlocation').data('ajaxlocation');

		// INITIATIONS
		AOS.init();

		// CUSTOM FUNCTIONS
		carouselAnimation();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		SliderLayout();
		slickResponsive();
		slickResponsiveThumb();
		pageBanner( $navHeight );
		masonry();
		fileReader();
		calendarEventSlider();
		videoSlider();
		branchScroll();

		$('a[data-rel^=lightcase]').lightcase();
		$('.video-btn').lightcase({
			attrPrefix: 'vl-',
			classPrefix: 'video-',
			showCaption: false,
			showSequenceInfo: false,
			width: 800,
			height: 500,
			maxWidth: 800,
			maxHeight: 500
		});
		$('.lightcaseGallery').each(function(){
			var $caption = $(this).attr('data-caption');
			$(this).lightcase({
				caption: $caption,
				showCaption: true,
				showSequenceInfo: false,
			});
		});
		$(document).on('click', '.video-btn', function(){
			$('#lightcase-info').addClass('hidden');
		});
		filter_country_state_city("select.csCountryOption", "select.csStateOption", "select.csCityOption");

		$('.loader-overlay').fadeOut(200);

		$(document).on('click', '.mobile-menu-btn', function(){
			$('.menu-button').toggleClass('active');
			$('main.main-panel').toggleClass('toggle');
		});
		$(document).on('click', '.main-overlay', function(){
			$('.menu-button').toggleClass('active');
			$('main.main-panel').toggleClass('toggle');
			$('.sidepanel').toggleClass('sidepanel-out');
		});
		

		$(document).on('click', '#mobile-search', function(){
			$('.mobile-search-form').toggleClass('show');
			$(this).find('i').toggleClass('fa-search fa-close');
		});

		$(document).on('click', '.option-filter li', function(){
			$(this).find('ul').toggleClass('open');
		});

		$('.option-filter li').each(function(){
			if( $(this).hasClass('active') ) {
				var active = $(this).find('a').text();
				$('.selectedOption').text( active );
			}
		});

		$(document).on('click', '.archiveFilterOption li', function(){
			$(this).find('ul').toggleClass('open');
		});

		$('.archiveFilterOption').each(function(){
			var $parent = $(this);
			$(this).find('li').each(function(){
				if( $(this).hasClass('active') ) {
					var active = $(this).find('a').text();
					$parent.find('.selectedOption').text( active );
				}
			});
		});

		$(document).on('click', '.inquire-btn', function(e){
			e.preventDefault();
			var title = $(this).data('title');
			$('#inquiry .programTitle').text(title);
			$('#inquiry .program-title input').val(title);
			$('#inquiry').modal('show');
		});

		$(document).on('click', '.branchCategories li a', function(e){
			e.preventDefault();
			var id = $(this).data('termid');
			$('.branchCategories li').removeClass('active');
			$(this).parent('li').addClass('active');
			$.ajax({
				url : $ajaxUrl,
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_branch',
					term_id : id,
				},
				beforeSend: function(){
					$('.branchListingContainer .rendering').fadeIn(200);
				},
				success: function(response) {
					$('.branchListingContainer .rendering').fadeOut(200);
					$('.branchListingContainer .branchListing').html(response.branch);
				}
			});
		});

		$(document).on('click', '.more-details', function(e){
			e.preventDefault();
			var id = $(this).data('id');
			var $title = $(this).data('title');
			var $ajaxUrl = $('.ajaxlocation').data('ajaxlocation');
			$.ajax({
				url : $ajaxUrl,
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_career',
					id: id,
				},
				beforeSend: function(){
					$('.loader-overlay').fadeIn(200);
				},
				success: function(response) {
					$('.loader-overlay').fadeOut(200);
					$('#careerDetails .heading').text($title);
					$('#careerDetails .career-detail-container').html(response.content);
					$('#careerDetails').modal('show');
				}
			});
		});
		$(document).on('click', '.careerApplyNow', function(e){
			e.preventDefault();
			var $title = $(this).data('title');
			$('#careerDetails').modal('hide');
			$('#careerApplication .heading').text('Applying for '+$title);
			$('#careerApplication .position input').val($title);
			$('#careerApplication').modal('show');
		});

		$(document).on('click', '.close-password', function(){
			$('.password-container').fadeOut(300);
		});

		$(document).on('click', '.postEvent-btn', function(){
			var id = $(this).data('id');
			var $ajaxUrl = $('.ajaxlocation').data('ajaxlocation');
			$.ajax({
				url : $ajaxUrl,
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_calendar_details',
					id: id,
				},
				beforeSend: function(){
					$('.loader-overlay').fadeIn(200);
				},
				success: function(response) {
					$('.loader-overlay').fadeOut(200);
					$('#calendarDetails .calendarContent').html(response.content);
					$('#calendarDetails').modal('show');
				}
			});
		});

		if( $('.contactusLayout .branch') ) {
			$.ajax({
				url : $ajaxUrl,
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_branches',
				},
				beforeSend: function(){
				},
				success: function(response) {
					$('.contactusLayout .branch select').html(response.content);
				}
			});
		}

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		// fullScreen();

	});
});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
	fullScreen();
	if( $('.careerArchive') ){
		var $ajaxUrl = $('.ajaxlocation').data('ajaxlocation');
		$.ajax({
			url : $ajaxUrl,
			type : 'POST',
			dataType: 'json',
			data : {
				action : 'request_background',
			},
			success: function(response) {
				$('.careerArchive').css({
					'background-image': 'url('+response.background+')'
				});
			}
		});
	}

	var maxHeight = 0;
	$(".slide-equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".slide-equalize").height(maxHeight);
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	if( $('.careerArchive') ){
		var $ajaxUrl = $('.ajaxlocation').data('ajaxlocation')
		$.ajax({
			url : $ajaxUrl,
			type : 'POST',
			dataType: 'json',
			data : {
				action : 'request_background',
			},
			success: function(response) {
				$('.careerArchive').css({
					'background-image': 'url('+response.background+')'
				});
			}
		});
	}

	var maxHeight = 0;
	$(".slide-equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".slide-equalize").height(maxHeight);
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){

	var maxHeight = 0;
	$(".slide-equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".slide-equalize").height(maxHeight);
}
else{
	var maxHeight = 0;
	$(".slide-equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".slide-equalize").height(maxHeight);
}

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/
function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight = 0, $navHeight = 0, $footerHeight = 0 ){
	$('.wrapper-holder').css({
		'min-height': $pageHeight + $navHeight,
		// 'margin-top': $navHeight,
		'padding-bottom': $footerHeight - 2
	});
	$('.main-layout').css({
		'min-height': $pageHeight + ( $navHeight - $footerHeight ),
	});
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" , 1000);
		$(this).toggleClass('toggle-mobile-menu', 1000);
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out", 1000);
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});
}
function cf7formsubmit(){
	var sitelink = $('.usd').data('usdacct');
	document.addEventListener( 'wpcf7mailsent', function( event ) {
	  location = sitelink + '/thank-you/';
	}, false );
}

function SliderLayout() {
  $('.owl-carousel').owlCarousel({
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      autoplay:true,
      dots: false,
      items:1,
      slideSpeed : 200,
      mouseDrag  : false,
      nav: false,
      paginationSpeed : 400,
      touchDrag: false,
      singleItem:true,
      smartSpeed:450,
      loop:true,
  });
}

function fullScreen(){
	var $pageHeight = $(window).height();
	var $addedHeight = 0;
	if( $pageHeight > 900 ){
		$addedHeight = 0;
	}else{
		$addedHeight = 200;
	}
	$('.fullScreen').css({
		height: $pageHeight + $addedHeight
	});
}

function slickResponsive(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function slickResponsiveThumb(){
	$('.slick-responsiveThumb').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});

	$('.slick-responsiveThumb-trail').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  fade: true,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('trail'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function pageBanner( $navheight ){
	$('.page-banner').css({
		'margin-top' : $navheight
	});
}

function masonry(){
	$('.masonry').each(function(){
		var item = $(this).find('.masonry-item');
		$(this).isotope({
			itemSelector: '.masonry-item',
			percentPosition: true,
			masonry: {
				columnWidth: '.masonry-item',
			}
		});
	});
}

function fileReader(){
	$(document).on('click', '.file-browse-btn', function(){
		$('.cv input').click();
	});

	$('.cv input').change(function(e){
		var FileName = e.target.files[0].name;
		$('.fileoutput input').val(FileName);
	});
}

function calendarEventSlider(){
	$('.calendarSlider').slick({
	  dots: false,
	  infinite: true,
	  speed: 300,
	  autoplay: true,
	  arrows: true,
	  slidesToShow: 2,
	  slidesToScroll: 2,
	  adaptiveHeight: true,
	  responsive : [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 659,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		],
	  prevArrow : '<button type="button" class="calendar-prev slick-controller fa fa-chevron-left"></button>',
	  nextArrow : '<button type="button" class="calendar-next slick-controller fa fa-chevron-right"></button>',
	});
}

function videoSlider(){
	$('.video-lists').slick({
	  dots: false,
	  infinite: true,
	  speed: 300,
	  autoplay: true,
	  arrows: true,
	  slidesToShow: 4,
	  slidesToScroll: 1,
	  adaptiveHeight: true,
	  responsive : [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 659,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		],
	  prevArrow : '<button type="button" class="video-prev slick-controller fa fa-chevron-left"></button>',
	  nextArrow : '<button type="button" class="video-next slick-controller fa fa-chevron-right"></button>',
	});
}

function branchScroll(){
	$(document).on('click', '.inquire-branch-btn', function() {
		var branch = $(this).data('title');
		$('.contactusLayout .branch select option').removeAttr('selected');
		$('.contactusLayout .branch select option').each(function(){
			if( $(this).val() == branch ){
				$(this).prop('selected', 'selected');
			}
		});
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		  var target = $(this.hash);
		  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		  if (target.length) {
		    $('html, body').animate({
		      scrollTop: target.offset().top - 100
		    }, 1000);
		    return false;
		  }
		}
	});
}